const Swal = require('sweetalert2')

const isEmailValid = (email) => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(String(email).toLowerCase())
}

const getReportPdfPath = (path) => {
  let pos = path.indexOf("/report")
  return path.substr(pos)
}

const alertMessage = (message, isSucceed = false) => {
  return Swal.fire({
    icon: isSucceed ? 'success' : 'error',
    title: 'การแจ้งเตือน',
    text: message,
    confirmButtonText: 'ตกลง',
    animation: false,
    backdrop: 'rgba(9, 71, 115, 0.8)'
  })
}

const toastMessage = (message, isSucceed = false) => {
  return Swal.fire({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2400,
    timerProgressBar: true,
    imageUrl: isSucceed ? '/img/check.png' : '/img/wrong.png',
    background: isSucceed ? '#299340' : '#DB3A3A',
    imageWidth: 32,
    imageHeight: 32,
    title: message,
    animation: false,
  })
}

module.exports = {
  isEmailValid,
  alertMessage,
  toastMessage,
  getReportPdfPath
}
